@import "palettes";
@import "theme";
@import "mixins";

header {
  //position: -webkit-sticky;
  //position: sticky;
  //top: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px;
  color: $white;
  background: $black;
  box-shadow: $shadow;


  & * {
    font-family: $sans;
  }

  .logo_row {
    @include inline-flex-center;

    .logo > * {
      max-width: 100%;
      height: 40px;
    }

    .logo_text {
      margin: 0px 10px;
      font-size: 1.5rem;
      text-align: center;
    }

  }

  .nav_row {
    @include inline-flex-center;
    font-size: 0.8rem;
    font-weight: $regular;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-right: 10px;


    &:hover .link {
      opacity: 0.25;
    }

    .link:hover {
      opacity: 1;
    }

    .burger {
      display: none;
      font-size: 24px;
    }
  }

  a {
    color: currentColor;
    text-decoration: none;
  }

  // screen width where nav bar wraps to under logo
  // adjust based on length of your lab title and # of nav links

  // screen width where logo and nav bar go from horizontal to vertical
  // adjust based on length of your lab title and # of nav links
/*  @media (max-width: 600px) {
    .logo_row {
      flex-direction: column;
    }

    .nav_row {
      flex-direction: column;
      justify-content: center;
      width: 100%;

      .link {
        width: 100%;
      }
    }
  }*/
  @media (max-width: 800px) {
    align-content: center;

    .logo_row,
    .nav_row {
      //width: 100%;
    }
  }

  .dropbtn {
  background-color: #ffffff00;
  color: white;
  padding: 8px;
  font-size: 24px;
  border: none;
  cursor: pointer;
  }

  //.dropbtn:hover, .dropbtn:focus {
  //  background-color: #3e8e41;
  //}

  @media screen and (max-width: 800px) {
  .nav_row a:not(:last-child) {display: none;}
  .dropdown#burger {
    float: right;
    display: block;
  }

  }

//  .logo_row {
//    flex-direction: column;
//  }

}

.dropdown {
//    float: right;
//    position: relative;
    right:0;
//    display: inline-block;
//    z-index: 9;
}

.dropdown-content {
  display: none;
  position: absolute;
  top:40px;
  right:0px;
  background-color: #f0f0f0;
  min-width: 100vw;
//  overflow: auto;
  box-shadow: 0px 15px 30px 0px rgba(0,0,0,0.7);
//  right: 0;
  z-index: 9;
}

.dropdown-content a {
  color: black;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
  font-size: 1.6rem;
  font-weight: $regular;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align:center;
}

//.dropdown a:hover {background-color: #ddd;}

//  .show {display: block;}

.dropdown#burger {
  display: none;
  font-size: 24px;
}

.show {display:block;}


/*
.second_nav {

//  @include inline-flex-center;
  font-weight: $regular;
  text-transform: uppercase;
  letter-spacing: 1px;
  //top: 20px;
  background: $white;
  z-index:9;
  position: absolute;
  right:5px;
  top:59px;
  margin-right: 0px;
//  flex-direction: column;

  width: 70%;
  display:none;
  box-shadow: 0 0 5px 0 #00000060;

  .link {
    font-size: 1.9rem;
    display:block;
    text-align:right;
    padding:5px;
  }

}
*/
